<template>
  <!-- 预付费配置-编辑-新增 -->
  <div class="dataMigration-wrapper">
    <!--主体内容-->
    <div class="content">
      <!-- <div class="topback">
        <el-button @click="back" size="medium" type="primary">返回</el-button>
      </div> -->
      <div class="save">
        <el-button @click="back" size="medium" class="cancle">返回</el-button>
      </div>
      <div class="contentImtem">
        <div class="conentLayout">
           <div class="contentF">配置名称： {{ formInline.configName }}</div>
           <div class="contentF">所属商户： {{ formInline.operationName }}</div>
        </div>
        <div class="conentLayout enter" v-if="formInline.selfEntry == 1">
          <span class="tipicon"></span><b class="tiptitle">自主入场模式</b>
          <div>
            适用于PDA泊位，开启自主入场后，车主在车场的有效范围内可以自主入场，车主可以在移动端进行自主停车，查看<span
              class="blueColor"
              @click="dialogVisible = true"
              >移动端呈现效果图</span
            >。
          </div>
          <div>配置有效范围：{{ formInline.entryDistance }}米</div>
          <div>配置免费时长:{{ formInline.freeTime }}分钟</div>
        </div>
        <div class="conentLayout exit" v-if="formInline.selfExit == 1">
          <span class="tipicon"></span><b class="tiptitle">自主离场模式</b>
          <div>
             开启【申请驶离】车主可以在移动端自主驶离，查看<span
              class="blueColor"
              @click="dialogVisible2 = true"
              >移动端呈现效果图</span
            >。开启【场中支付】车主可以在移动端支付驶离,查看<span
              class="blueColor"
              @click="dialogVisible1 = true"
              >移动端呈现效果图</span
            >。
          </div>
          <div>配置驶离规则：</div>
          <div v-if="formInline.exitRule == '1'">
            PDA全程审核：
            <div class="tipdetail">
              车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”
            </div>
          </div>
          <div v-if="formInline.exitRule == '2'">
            仅限免费时长启动PDA审核：
            <div class="tipdetail">
              在免费时长内，车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”
            </div>
          </div>
          <div v-if="formInline.exitRule == '3'">
            信赖车主：
            <div class="tipdetail">
              信赖车主，以车主自主驶离的【申请/支付】时间为停车记录的截止时间
            </div>
          </div>
           <div v-if="formInline.exitRule == '4'">
            取信地磁：
            <div class="tipdetail">
              取信地磁，以地磁上报驶离的时间为停车记录的截止时间，车主驶离即生成欠费订单；视频泊位不支持取信地磁离场
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mask" v-show="dialogVisible" @click="dialogVisible = false">
      <div>
        <img src="./parkDetail.png" alt="" class="parkDetail" />
      </div>
    </div> -->
    <div
      class="mask"
      v-show="dialogVisible"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl})` }"
      @click="dialogVisible = false"
    ></div>
    <div
      class="mask"
      v-show="dialogVisible1"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl1})` }"
      @click="dialogVisible1 = false"
    ></div>
    <div
      class="mask"
      v-show="dialogVisible2"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl2})` }"
      @click="dialogVisible2 = false"
    ></div>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "addeditPrepaidConfig",
  data() {
    return {
      picUrl: require("./parkDetail.png"),
      picUrl1: require("./parkOrder.png"),
      picUrl2: require("./payDetail.png"),
      input: "10",
      preChargeSettingId: "",
      radioConfig: "1",
      value: true,
      value1: true,
      type: "add",
      tenantList: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      selfParkingId: "",
      isEdit: "",
      // entryDistance: "", // 有效范围
      formInline: {
        configName: "", // 预付费配置名称
        operationId: "", // 运营商ID
        selfEntry: "", // 0关闭 1开启
        selfExit: "", // 0关闭 1开启
        exitType: "", // 支付驶离 1   申请驶离2
        entryDistance: "", // 自主入场范围
        exitFreeTime: "", // 支付驶离时长
      },
    };
  },
  methods: {
    changeLabel() {
      this.$forceUpdate();
    },
    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    // 查询
    searchData() {
      this.$axios
        .get("/acb/2.0/selfParking/getBySelfParkingId", {
          data: {
            selfParkingId: this.selfParkingId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.formInline = res.value;
          }
        });
    },
  },
  created() {
    // this.getTenantList();
  },
  mounted() {
    console.log(this.$route, "route");
    this.selfParkingId = this.$route.query.selfParkingId
      ? this.$route.query.selfParkingId
      : "";
    this.searchData();
  },
};
</script>
<style lang="stylus">
.dataMigration-wrapper {
  background: #F4F7F9;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  padding: 0 24px;
  font-size: 12px;
}

.conentLayout
  background: #fff;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;

.blueColor {
  color: rgba(12, 102, 255, 1);
  cursor: pointer;
  font-size: 12px;
}

.tipicon {
  width: 4px;
  height: 15px;
  background: #0C66FF;
  display: inline-block;
}

.tiptitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2F2F51;
  line-height: 20px;
  padding-left: 12px;
}


.parkDetail {
  position: absolute;
  width: 375px;
  height: 812px;
  left: 50%;
  top: 50%;
  margin-top: -406px;
  margin-left: -188px;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.contentF
  padding-left: 20px;
  padding-top: 16px;
.contentImtem {
  margin-top: 40px;
  // background-color: #fff;
  padding: 16px;
}
.contentImtem div
  font-size: 12px;
.enter div {
  padding: 20px 0px 0px 20px;
}

.exit>div {
  padding: 20px 0px 0px 20px;
}

.tipdetail {
  padding-left: 0px;
  padding-top: 20px; 
}
.save
  float: right;
  margin-top: -30px;
  margin-right: 15px;
>>>.el-button
  width: 96px;
  border-radius: 4px;
.cancle
  background: #527BFF;
  color: #fff;
</style>
